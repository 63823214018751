import React, { useEffect, useState } from "react";
import {
  Typography,
  Switch,
  FormControlLabel,
  Collapse,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import StyledPanel from "../../StyledPanel";
import CreateOnePass from "../../../OnePass/CreateOnePass";
import { useStyles } from "./styles";

const OnePassPanel = ({
  className,
  accessGroups,
  rates,
  onePassDetails,
  onSave,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));

  const handleEnableOnePass = () => {
    if(onePassDetails?.accessGroupId) {
      onSave({
        ...onePassDetails,
        active: !onePassDetails.active,
      });
    }
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(onePassDetails?.active || false)
  }, [onePassDetails])
  return (
    <StyledPanel
      className={className}
      headerContent={
        <div className={classes.header}>
          <div className={classes.headerLeft}>
            <Typography>ONE Pass</Typography>
          </div>
          <div className={clsx(["toggle-btn-header", classes.headerButtons])}>
            <FormControlLabel
              control={
                <Switch
                  checked={expanded}
                  data-checked={expanded}
                  onChange={handleEnableOnePass}
                  name="checkedB"
                  color="primary"
                />
              }
              label={!small && "Enable ONE Pass on this account"}
            />
          </div>
        </div>
      }
      cardContent={
        <Collapse
          in={expanded}
          timeout="auto"
          unmountOnExit
          className={clsx(["create-onepass-fields", classes.collapsible])}
        >
          <CreateOnePass
            options={accessGroups}
            onePassDetails={onePassDetails}
            rates={rates}
            onSave={onSave}
          />
        </Collapse>
      }
    ></StyledPanel>
  );
};

export default OnePassPanel;
