import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import StyledPanel from "../StyledPanel";
import { useStyles } from "./styles";
import { Typography, List, ListItem } from "@material-ui/core";
import VehicleForm from "../../Forms/Vehicle";
import ColoredLine from "../../ColoredLine";
import apiClient from "../../../auth/apiClient";
import VehicleService from "../../../services/VehicleService";
import ContractService from "../../../services/ContractService";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { isUndefined } from "lodash";
import clsx from "clsx";
import { generateUUID } from "../../Rate";

const vehicleService = new VehicleService(apiClient);
const contractService = new ContractService(apiClient);

const VehiclePanel = ({
  className,
  disabled,
  accessHolderID,
  facilityID,
  contractID,
  assignedAccessHolderName,
}) => {
  const classes = useStyles();
  const [vehicles, setVehicles] = React.useState();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [accessHolderInfo, setAccessHolderInfo] = useState();

  useEffect(() => {
    if (!isUndefined(accessHolderID) && !isUndefined(facilityID)) {
      fetchVehicles();
      fetchAccessHolder(facilityID, accessHolderID);
    }
  }, [facilityID, accessHolderID]);

  const fetchAccessHolder = useCallback(
    async (facilityID, accessHolderID) => {
      let response;
      try {
        response = await contractService.getAccessHolder(
          facilityID,
          accessHolderID
        );
      } catch {
        enqueueSnackbar("Failed to retrieve Access Holder", {
          variant: "error",
          tag: "fetchAccessHoldersError",
        });
        return;
      }
      let email;
      if (response.data.contactInfo?.emails) {
        email = response.data.contactInfo.emails[0]?.emailAddress;
      }
      setAccessHolderInfo({
        email,
        firstName: response?.data?.contactInfo?.firstName,
        lastName: response?.data?.contactInfo?.lastName,
      });
    },
    [facilityID, accessHolderID]
  );

  const fetchVehicles = useCallback(async () => {
    if (!accessHolderID) {
      setVehicles();
      return;
    }

    let response;
    try {
      response = await vehicleService.GetVehiclesForAccessHolder(
        accessHolderID,
        100,
        0,
        ""
      );
    } catch {
      enqueueSnackbar("Failed to retrieve vehicles", {
        variant: "error",
        tag: "getVehiclesError",
      });
      return;
    }

    let tmp = response.data?.collection ?? [];
    tmp.push({ accessHolderID });
    setVehicles(tmp);
  }, [accessHolderID]);

  const handleRemoveVehicle = (vehicleID) => {
    let tmpVehicles = vehicles;
    tmpVehicles = tmpVehicles.filter((x) => x.vehicleID !== vehicleID);
    setVehicles(tmpVehicles);
  };

  const handleAddVehicle = async () => {
    await fetchVehicles();
  };

  const generateUniqueID = useCallback((index) => `${index}${Date.now()}`);

  return (
    <StyledPanel
      className={clsx("vehicle-panel", className)}
      headerContent={
        <Typography className={clsx("vehicles")}>Vehicles</Typography>
      }
      cardContent={
        <List className={clsx("vehicles-list", classes.credentialList)}>
          {vehicles?.map((vehicle, index) => {
            return (
              <>
                <ListItem
                  key={generateUniqueID(index)}
                  className={clsx(`vehicle-list-item`, classes.rowContainer)}
                >
                  <VehicleForm
                    className={clsx("vehicle-form")}
                    data={{ ...vehicle, accessHolderID, contractID }}
                    email={accessHolderInfo?.email}
                    disabled={disabled}
                    onDelete={handleRemoveVehicle}
                    onSubmit={handleAddVehicle}
                    assignedAccessHolderName={assignedAccessHolderName}
                  />
                </ListItem>
                <ColoredLine />
              </>
            );
          })}
        </List>
      }
    />
  );
};

VehiclePanel.propTypes = {
  className: PropTypes.string,
  accessHolderID: PropTypes.string,
  disabled: PropTypes.bool,
};

export default VehiclePanel;
