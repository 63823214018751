import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import StyledPanel from "../StyledPanel";
import { useStyles } from "./styles";
import { Button, Grid, Typography } from "@material-ui/core";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { isUndefined } from "lodash";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import FlexibleParkingAccountForm from "../../Forms/ContractDetails/FlexibleParkingAccount";
import EditBalanceButton from "../../Buttons/EditBalance";
import ContractService from "../../../services/ContractService";
import apiClient from "../../../auth/apiClient";
import * as c from "../../../constants";
import CreditCardOnFileButton from "../../Buttons/CreditCardOnFile";
import { useLocation } from "react-router-dom";

const contractService = new ContractService(apiClient);

const FlexibleParkingAccountPanel = ({
  className,
  disabled,
  accessHolderID,
  facilityID,
  contractId,
  viewPermission,
}) => {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const theme = useTheme();
  const [contractID, setContractID] = useState(contractId);
  const [currentBalance, setCurrentBalance] = useState();
  const [fundID, setFundID] = useState();
  const location = useLocation();
  const cardOnFileMessage = location.state?.cardOnFileMessage;
  const facilityGroupId = useSelector((state) => state.entityScope?.facilityGroupId);
  const scopeAwareFacilityID = facilityGroupId || facilityID;

  const [modalCardOnFileOpen, setModallCardOnFileOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(c.FPA_PAYMENT_METHODS["CHARGE_FPA"]);
  const [contractInfo, setContractInfo] = useState();

  const handlePayMentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  }

  const toggleModalCardOnFileOpen = () => {
    setModallCardOnFileOpen(!modalCardOnFileOpen);
  };

  const updateContractInfo = (newContractInfo) => {
    setContractInfo(newContractInfo);
  }

  const fetchBalance = useCallback(async () => {
    if (!contractID || !scopeAwareFacilityID) {
      setCurrentBalance(0);
      setFundID(null);
      return;
    }

    let response;
    try {
      response = await contractService.getFundBalance(scopeAwareFacilityID, contractID);

      if (response?.data) {
        setCurrentBalance(response.data?.balance);
        setFundID(response.data?.fundID);
      }
    } catch {
      enqueueSnackbar("Failed to retrieve funds balance", {
        variant: "error",
        tag: "getFlexibleParkingAccountFunds",
      });
      return;
    }
  }, [contractID, facilityID, enqueueSnackbar]);

  const displayCardOnFileErrMess = useCallback(async () => {
    if (!cardOnFileMessage) {
      return;
    }

    if (cardOnFileMessage.toLowerCase() == "success" || cardOnFileMessage.toLowerCase() == "pending") {
      toggleModalCardOnFileOpen();
      return;
    }
    if (cardOnFileMessage.toLowerCase() == "cancel") {
      enqueueSnackbar("Credit card on file was cancelled", {
        variant: "warning",
        tag: "getCardOnFileErrMess",
      });
      return;
    }
    if (cardOnFileMessage.toLowerCase() == "fail") {
      enqueueSnackbar("Credit card on file failed", {
        variant: "error",
        tag: "getCardOnFileErrMess",
      });
    }
  }, [cardOnFileMessage]);

  const fetchContractIdByAccessHolder = useCallback(async () => {
    if (!accessHolderID || !scopeAwareFacilityID) {
      setContractID();
      return;
    }

    let response;
    try {
      response = await contractService.getAccessHolder(
        scopeAwareFacilityID,
        accessHolderID
      );
      setContractID(response.data?.contractID);
    } catch {
      enqueueSnackbar("Failed to retrieve access holder status info", {
        variant: "error",
        tag: "accessHolderStatusInfoError",
      });
    }
  }, [accessHolderID, facilityID, enqueueSnackbar]);

  const fetchContractDetails = useCallback(async () => {
    if (!contractID || !facilityID) {
      setContractInfo();
      return;
    }
    let response;

    try {
      response = await contractService.getContractDetails(
        facilityID,
        contractID,
        c.CONTRACT_DETAILS_TYPE["Flexible Parking Account"]
      );
    } catch {
      enqueueSnackbar("Failed to retrieve Flexible Parking Account", {
        variant: "error",
        tag: "getFlexibleParkingAccount",
      });
      return;
    }

    if (response?.data?.payload) {
      setContractInfo(response.data);
      setPaymentMethod(response.data.payload?.paymentMethod);
    }
  }, [contractID, facilityID]);

  useEffect(() => {
    if (!isUndefined(contractID) && !isUndefined(facilityID)) {
      fetchContractDetails();
    }
  }, [facilityID, contractID, fetchContractDetails]);

  useEffect(() => {
    if (
      !isUndefined(accessHolderID) &&
      !isUndefined(facilityID) &&
      viewPermission
    ) {
      fetchContractIdByAccessHolder();
    }
  }, [facilityID, accessHolderID, facilityGroupId]);

  useEffect(() => {
    if (!isUndefined(contractID) && viewPermission) {
      fetchBalance();
    }
  }, [contractID, fetchBalance]);

  useEffect(() => {
    if (!isUndefined(cardOnFileMessage) && viewPermission) {
      displayCardOnFileErrMess();
    }
  }, [cardOnFileMessage, displayCardOnFileErrMess, viewPermission]);

  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledPanel
      className={clsx("flexible-account-panel", className)}
      headerContent={
        <div className={clsx("header", classes.header)}>
          <div className={clsx("header-title")}>
            <Typography className={clsx("header-left", classes.headerLeft)}>
              {smallScreen ? "Flex" : "Flexible Parking Account"}
            </Typography>
          </div>
          {(!paymentMethod || paymentMethod === c.FPA_PAYMENT_METHODS["CHARGE_FPA"]) && (
            <div
              data-testid="balance"
              className={clsx("header-balance", classes.headerBalance)}
            >
              Balance $
              {isUndefined(currentBalance) ? "0.00" : currentBalance.toFixed(2)}
            </div>
          )}
        </div>
      }
      cardContent={
        <Grid container item xs={12} lg={12} spacing={2}>
          <Grid item xs={12} lg={9}>
            <FlexibleParkingAccountForm
              contractID={contractID}
              facilityID={facilityID}
              disabled={disabled}
              paymentMethod={paymentMethod}
              handlePayMentMethodChange={handlePayMentMethodChange}
              contractInfo={contractInfo}
              updateContractInfo={updateContractInfo}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <Grid container item xs={12} spacing={2} alignItems="flex-start">
              {(!paymentMethod || paymentMethod === c.FPA_PAYMENT_METHODS["CHARGE_FPA"]) && (
                <Grid item>
                  <EditBalanceButton
                    currentBalance={currentBalance}
                    setCurrentBalance={setCurrentBalance}
                    contractID={contractID}
                    entityID={facilityID}
                    fundType={c.FUND_TYPE.Amount}
                    fundID={fundID}
                    setFundID={setFundID}
                    disabled={disabled}
                  />
                </Grid>
              )}
              <Grid item>
                <CreditCardOnFileButton
                  contractID={contractID}
                  accessHolderID={accessHolderID}
                  disabled={disabled}
                  toggleModal={toggleModalCardOnFileOpen}
                  modalOpen={modalCardOnFileOpen}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

FlexibleParkingAccountPanel.propTypes = {
  className: PropTypes.string,
  contractID: PropTypes.string,
  facilityID: PropTypes.string,
  fundID: PropTypes.string,
};

export default FlexibleParkingAccountPanel;
