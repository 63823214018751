import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    display: "inline-flex",
    justifyContent: "space-between",
    justify: "center",
    flex: 1,
    boxSizing: "border-box",
    [theme.breakpoints.up("lg")]: {
      maxHeight: "20px",
    },
  },
  headerLeft: {
    display: "inline-flex",
    [theme.breakpoints.down("sm")]: {
      display: "inline-block",
    },
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  headerButtons: {
    display: "block",
    marginTop: theme.spacing(-1),
  },
  collapsible: {
    width: "100%"
  }
}))